import React from 'react'
import {
  Dialog
} from '@mui/material'
import { AccountF } from '@logic/contexts/Accounts/AccountsFrontend.type.ts'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'

interface AddressInfoProps {
  open: boolean
  onClose: () => void
  account: AccountF | null
}

const AddressInfo: React.FC<AddressInfoProps> = ({
  open,
  onClose,
  account,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <div className={styles.dialogHeader}>
        <h3>Address Details - {account?.accountName}</h3>
      </div>

      <div className={styles.dialogContent}>
        <div className={cn(styles.headerRow, styles.header8Row)}>
          <strong>Type:</strong>
          <strong>Address:</strong>
          <strong></strong>
          <strong></strong>
          <strong>City:</strong>
          <strong>State:</strong>
          <strong>Country:</strong>
          <strong>Postcode:</strong>
        </div>

        {account &&
          account.addresses?.map((address, idx) => (
            <div className={cn(styles.infoRow, styles.info8Row)} key={idx}>
              <span>
                {address.addressTypes?.charAt(0).toUpperCase() +
                  address.addressTypes?.slice(1)}
              </span>
              <span>{address.addressLine1}</span>
              <span>{address.addressLine2}</span>
              <span>{address.addressLine3}</span>
              <span>{address.city}</span>
              <span>{address.state}</span>
              <span>{address.country}</span>
              <span>{address.postcode}</span>
            </div>
          ))}
      </div>

      <div className={styles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Close</span>
        </div>
      </div>
    </Dialog>
  )
}

export default AddressInfo
