import React, { useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import styles from '../AccountList.module.scss'
import LearnerStyles from './LearnerSkillMap.module.scss'
import modalStyles from '@components/modal/modal.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { cn } from '@src/utils/cn.ts'
import { useDataProvider, useNotify } from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import mermaid from 'mermaid'
import panzoom from 'panzoom'

interface SkillMapProps {
  open: boolean
  onClose: () => void
  learner: any
}

const LearnerSkillMap: React.FC<SkillMapProps> = ({ open, onClose, learner }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const learnerId = learner?.id

  const [skillMap, setSkillMap] = useState<string | null>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [chartData, setChartData] = useState<string | null>('')

  useEffect(() => {
    if (open && learnerId) {
      const fetchSkillMap = async () => {
        setIsDataLoading(true)
        setSkillMap(null)
        try {
          const response = await dataProvider.getOne('Learner', {
            id: learnerId,
            method: 'getSkillMap',
          })
          const skillMapData = response.data?.skill_map
          setSkillMap(skillMapData)
        } catch (error: any) {
          notify(error.message || 'Failed to load skill map', { type: 'error' })
        } finally {
          setIsDataLoading(false)
        }
      }
      fetchSkillMap()
    }
  }, [open, learnerId, dataProvider, notify])

  // Render Mermaid Chart
  useEffect(() => {
    if (skillMap) {
      const renderMermaidChart = async () => {
        try {
          mermaid.initialize({ startOnLoad: false })
          const { svg } = await mermaid.render('mermaidChart', skillMap)
          setChartData(svg)
        } catch (error) {
          console.error('Mermaid rendering failed:', error)
        }
      }
      renderMermaidChart()
    }
  }, [skillMap])

  useEffect(() => {
    if (chartData) {
      const container = document.getElementById('chartWrapper')
      const svgElement = container?.querySelector('svg')

      if (svgElement && container) {
        // Set container to have a fixed size and prevent overflow
        container.style.position = 'relative'
        container.style.overflow = 'hidden'
        container.style.width = '90%'

        const instance = panzoom(svgElement, {
          maxZoom: 2,
          minZoom: 0.5,
          zoomSpeed: 0.1,
          bounds: true,
          boundsPadding: 0.1,
          pinchSpeed: 1,
        })

        return () => {
          instance.dispose()
        }
      }
    }
  }, [chartData])

  const handleCloseDialog = () => {
    setSkillMap(null)
    setChartData(null)
    onClose()
  }

  const handlePrintChart = () => {
    const printWindow = window.open('', '_blank', 'width=800,height=700')
    if (printWindow) {
      printWindow.document.open()
      printWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Skill Map - ${learner?.name}</title>
          <style>
          body {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
          
            background: white;
            font-family: Arial, sans-serif;
          }
          h2 {
            text-align: center;
            margin-bottom: 5px;
          }
          h6 {
            margin-bottom: 2px;
          }
          svg {
            max-width: 100%;
            max-height: 100%;
          }
          </style>
        </head>
        <body>
          <div>
            <h1>Skill Map - ${learner?.name}</h1>
            <h6>AccountName: ${learner?.accountName}</h6>
            <h6>Country: ${learner?.country}</h6>
          </div>
          <hr style="width:100%;margin-bottom: 10px">
          ${chartData}
        </body>
        </html>
      `)
      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth='lg' fullWidth>
      <div className={cn(modalStyles.dialogHeader, LearnerStyles.headerContent)}>
        <h3>Skill Map - {learner?.name}</h3>
        <div className={LearnerStyles.headerButtons}>
          <div className={cn(modalStyles.dialogActions)}>
            <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={handlePrintChart}>
              <span>Print</span>
            </div>

            <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onClose}>
              <span>Close</span>
            </div>
          </div>
        </div>
      </div>

      <div className={cn(modalStyles.dialogContent, LearnerStyles.skillMapHeight)} id='chartWrapper'>
        {isDataLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(skillMap) ? (
          <div className={sharedStyles.notFound}>No data found for the learner.</div>
        ) : (
          <div className={styles.chartWrapper}>
            {/* Inject the rendered SVG directly */}
            <div id='mermaidChartContainer' dangerouslySetInnerHTML={{ __html: chartData || '' }} className={styles.mermaidChart} />
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default LearnerSkillMap
