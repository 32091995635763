import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import styles from './GroupCurriculumEnroll.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LxSelectInput } from '@src/components/select/selectInput'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { Link, useNavigate } from 'react-router-dom'
import { LxIcon } from '@src/components/icon/Icon'
import { LxTooltip } from '@src/components/tooltip/tooltip'
import { InfoOutlineIcon } from '@src/assets/icons/utils'
import { ActivityPurpleIcon } from '@src/assets/icons'
import { useDataProvider, useNotify } from 'react-admin'
import React, { useState } from 'react'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import ActivitiesShowModal from './ActivitiesShowModal'

const schema = yup.object().shape({
  learner: yup.string().required('Please select a learner'),
})

export type LearnerFormType = {
  learner: string
}

interface CurriculumEnrollProps {
  curriculum: any
  allLearners: any
}

const GroupCurriculumEnroll: React.FC<CurriculumEnrollProps> = ({ curriculum, allLearners }) => {
  const { hideModal } = useModal()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [currentUnitId, setCurrentUnitId] = useState<string | null>(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LearnerFormType>({
    defaultValues: {},
    resolver: yupResolver(schema),
  })

  const resetAndNotify = (message, type) => {
    notify(message, { type })
    hideModal()
    navigate('/Learner')
  }

  const handleFormSubmit = async (formData: LearnerFormType) => {
    setIsSubmitting(true)

    try {
      await dataProvider.getOne('Goal', {
        id: formData.learner,
        curriculumId: curriculum.id,
        method: 'createCurriculumGoals',
      })

      const learnerName = getLearnerName(formData.learner)
      resetAndNotify(`The goals from ${curriculum.name} have been added to ${learnerName}'s assigned activities`, 'primary')
    } catch (error) {
      notify(error.message, { type: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const learnerOptions = allLearners.map((learner) => ({
    value: learner.id,
    label: learner.label,
  }))

  const getLearnerName = (value) => {
    const learner = learnerOptions.find((option) => option.value === value)
    return learner ? learner.label : null
  }

  const openActivityModal = (unitId: string) => {
    setCurrentUnitId(unitId)
    setIsActivityModalOpen(true)
  }

  const closeActivityModal = () => {
    setCurrentUnitId(null)
    setIsActivityModalOpen(false)
  }

  return (
    <>
      <div className={modalStyles.modalXl}>
        <div className={styles.modalContentContainer}>
          {isSubmitting && (
            <div className={styles.loadingOverlay}>
              <LxLoadingSpinner />
            </div>
          )}

          <div className={modalStyles.dialogHeader}>
            <h3>{curriculum.name}</h3>
          </div>

          <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
            <div className={modalStyles.dialogContent}>
              <div className={styles.description}>
                <span>{curriculum.description}</span>
              </div>

              {allLearners && (
                <div className={styles.learnerSelect}>
                  <Controller
                    name='learner'
                    control={control}
                    render={({ field }) => (
                      <LxSelectInput
                        value={field.value}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption ? selectedOption.value : '')
                        }}
                        options={learnerOptions}
                        placeholder={  curriculum.goals.length === 1 ? "Select learner to assign goal" : "Select learner to assign goals"}
                        hasError={!!errors.learner}
                      />
                    )}
                  />
                  {errors.learner && <span className={styles.errorMessage}>{errors.learner.message}</span>}
                </div>
              )}

              <div className={styles.scrollableGoals}>
                {curriculum.goals.map((goal, index) => (
                  <React.Fragment key={`${goal.name}-${index}`}>
                    <div className={styles.goalContainer}>
                      <div className={styles.goalHeader}>
                        <div>
                          <h5>Goal: {goal.name}</h5>
                        </div>
                      </div>
                    </div>

                    {!isNilOrEmpty(goal.units) &&
                      goal.units.map((unit, index) => (
                        <React.Fragment key={`${unit.title}-${index}`}>
                          <div className={styles.unitArea} onClick={() => openActivityModal(unit.unitId)}>
                            <span>
                              <LxIcon icon={ActivityPurpleIcon}></LxIcon>
                              {unit.title}
                            </span>
                            <LxTooltip tooltipText={`Read more`}>
                              <LxIcon icon={InfoOutlineIcon}></LxIcon>
                            </LxTooltip>
                          </div>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className={styles.dialogActions}>
              <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={() => hideModal()}>
                <span>Cancel</span>
              </div>
              <button
                type='submit'
                className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize', { disabled: isSubmitting })}
                disabled={isSubmitting}
              >
                <span>{  curriculum.goals.length === 1 ? "Assign Goal" : "Assign Goals"}</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <ActivitiesShowModal isOpen={isActivityModalOpen} onClose={closeActivityModal} unitId={currentUnitId ?? ''} />
    </>
  )
}

export default GroupCurriculumEnroll
