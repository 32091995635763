// PolicyTypes.ts

import { USER_ROLE } from "@src/logic/LuxAI"

export interface Policy {
  action: string
  resourceType: string
  effect: string
  conditions: Condition[]
}

export interface Condition {
  attribute: keyof User
  value: string
}

export interface User {
  id: string
  userRole: string[]
  roles: string[]
}

export const policyData: Policy[] = [
  {
    // only admin, account_admin , user can see create users
    action: 'create',
    resourceType: 'User',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only account_admin, admin can edit users
    action: 'edit',
    resourceType: 'User',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },

  // Learner policies
  {
    // only admin, account_admin , user can create learner
    action: 'create',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only account_admin, admin can edit users
    action: 'edit',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      },
      // {
      //   attribute: 'roles', 
      //   value: USER_ROLE.PARENT
      // }
    ]
  },
  
  {
    // only account_admin, admin,user can create goal
    action: 'add_goal',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only account_admin, admin,user can edit goal
    action: 'edit_goal',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only account_admin, admin , user can edit activity status
    action: 'edit_activity',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only account_admin, admin  can view analytics
    action: 'view_menu',
    resourceType: 'MyAccount',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      }
    ]
  },
  {
    // only account_admin, admin , user can edit activity status
    action: 'enroll_curriculum',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: USER_ROLE.ACCOUNT_ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.ADMIN
      },
      {
        attribute: 'userRole',
        value: USER_ROLE.USER
      }
    ]
  },
  {
    // only user with roles operations can see accounts menu
    action: 'view_menu',
    resourceType: 'Accounts',
    effect: 'any',
    conditions: [
      {
        attribute: 'roles', 
        value:  USER_ROLE.OPERATION
      }
    ]
  },
  {
    // only user with roles operations can see accounts menu
    action: 'view_menu',
    resourceType: 'Robots',
    effect: 'any',
    conditions: [
      {
        attribute: 'roles', 
        value:  USER_ROLE.OPERATION
      }
    ]
  },
  {
    // only user with roles operations can see accounts menu
    action: 'view_menu',
    resourceType: 'Subscriptions',
    effect: 'any',
    conditions: [
      {
        attribute: 'roles', 
        value:  USER_ROLE.OPERATION
      }
    ]
  },

]
