export const getVoiceLabel = (voiceCode) => {
    switch (voiceCode) {
      case 'en_US':
        return 'English US';
      case 'en_GB':
        return 'English UK';
      // Add more cases here as more voices are added
      default:
        return voiceCode;
    }
  };