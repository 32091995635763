import { buildQuery } from 'ra-data-graphql-simple'
import omitDeep from 'omit-deep'
import { gql } from 'graphql-tag'

export const myBuildQuery = (introspection: any) => (fetchType: string, resource: string, params: any) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params)

  if (fetchType === 'UPDATE') {
    return {
      ...builtQuery,
      // avoid errors due to __typename being included in embedded schema
      // see https://github.com/marmelab/react-admin/issues/2840
      variables: omitDeep(builtQuery.variables, '__typename'),
    }
  }

  if (resource === 'Goal' && fetchType === 'GET_ONE' && params.method == 'createCurriculumGoals') {
    const { id, curriculumId } = params
    return {
      ...builtQuery,
      query: gql`
        mutation createCurriculumGoals($learner_id: String!, $curriculum_id: String!) {
          data: createCurriculumGoals(learner_id: $learner_id, curriculum_id: $curriculum_id)
        }
      `,
      variables: { learner_id: id, curriculum_id: curriculumId },
      parseResponse: (response: any) => ({
        data: { id: response.data.data },
      }),
    }
  }

  if (resource === 'Robot' && fetchType === 'CREATE' && params.data.method === 'assignRobot') {
    const { id, accountId } = params.data
    return {
      ...builtQuery,
      query: gql`
        mutation AssignRobot($serial: String!, $customer_acc_id: String!) {
          data: assignRobot(serial: $serial, customer_acc_id: $customer_acc_id) {
            id
          }
        }
      `,
      variables: { serial: id, customer_acc_id: accountId },
      parseResponse: (response: any) => ({
        data: { id: response.data.data },
      }),
    }
  }

  if (resource === 'Learner' && fetchType === 'GET_ONE' && params.method == 'getSkillMap') {
    const { id } = params;
  
    return {
      ...builtQuery,
      query: gql`
        query getSkillMap($id: ID!) {
          learnerSkillMap(id: $id) {
            id
            skill_map
          }
        }
      `,
      variables: { id },
      parseResponse: (response: any) => ({
        data: response.data?.learnerSkillMap,  
      }),
    };
  }
  

  return builtQuery
}
