import React, { useContext, useMemo} from 'react';
import { useGetList } from 'react-admin';
import { SubscriptionB } from '@src/logic/contexts/Subscriptions/SubscriptionsBackend.type'
import { SubscriptionF } from '@src/logic/contexts/Subscriptions/SubscriptionsFrontend.type';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'

interface SubscriptionContextType {
  subscriptionList: SubscriptionF[] | null;
  isLoading: boolean;
  filterValues: FilterValues;
  dispatch: DispatchType;
}

const SubscriptionListContextDefault = {
  subscriptionList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    state: 'active'
  },
  dispatch: () => {},
};

const SubscriptionListContext = createContextFactory<SubscriptionContextType>(SubscriptionListContextDefault);

const mapServerSubscriptionListToClient = (data: SubscriptionB[] | undefined): SubscriptionF[] | null => {
  if (isNilOrEmpty(data)) {
    return null;
  }


  return data!.map((subscription: SubscriptionB) => ({
    id: subscription.id,
    customerId: subscription.customer_id,
    accountId: subscription.account_id,
    startDate: subscription.start_date,
    endDate: subscription.end_date,
    subscriptionTypId: subscription.subscription_type_id,
    accountName: subscription.account_name,
    status: subscription.status,
    paymentMethod: subscription.payment_method,
    nfPurchasedLicenses: subscription.number_of_purchased_licenses,
    nfUsedLicenses: subscription.number_of_used_licenses
  }));
};

export const SubscriptionListProvider: React.FC = ({ children, filter }) => {
  const initialFilterValues = { Q: '' };
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues);

  const { data, isLoading } = useGetList<SubscriptionB>(
    'Subscription',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'last_update', order: 'DESC' },
      filter: filter ? filter : filterValues
    }
  );

  const sanitizedSubscriptionList = useMemo(() => mapServerSubscriptionListToClient(data), [data]);

  return (
    <SubscriptionListContext.Provider value={{ subscriptionList: sanitizedSubscriptionList, filterValues, isLoading, dispatch }}>
      {children}
    </SubscriptionListContext.Provider>
  );
};

export const useSubscriptionsList = (): SubscriptionContextType => {
  return useContext(SubscriptionListContext);
};
