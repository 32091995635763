import React from 'react'
import { Dialog } from '@mui/material'
import styles from './RobotList.module.scss'
import modalStyles from '@components/modal/modal.module.scss'
import { formatDate } from '@src/utils/dateFormatter'
import { cn } from '@src/utils/cn.ts'

interface VersionInfoProps {
  open: boolean
  onClose: () => void
  versionInfo: {
    model: string
    softwareVersion: string
    versionNumber: string
    lastUpdate: string
    id: string
    serial: string
  } | null
}

const RobotVersionInfo: React.FC<VersionInfoProps> = ({ open, onClose, versionInfo }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <div className={modalStyles.dialogHeader}>
        <h3>Version Information - {versionInfo?.serial}</h3>
      </div>

      <div className={modalStyles.dialogContent}>
        {versionInfo ? (
          <>
            <div className={styles.infoRow}>
              <strong>Model:</strong>
              <span>{versionInfo.model}</span>
            </div>
            <div className={styles.infoRow}>
              <strong>Software Version:</strong>
              <span>{versionInfo.softwareVersion}</span>
            </div>
            <div className={styles.infoRow}>
              <strong>Version Number:</strong>
              <span>{versionInfo.versionNumber}</span>
            </div>
            <div className={styles.infoRow}>
              <strong>Last Update:</strong>
              <span>{ versionInfo.lastUpdate }</span>
            </div>
          </>
        ) : (
          <p>No version information available.</p>
        )}
      </div>

      <div className={cn(modalStyles.dialogActions, modalStyles.actionsFullWidth, styles.btnCentre)}>
        <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={() => onClose()}>
          <span>Close</span>
        </div>
      </div>
    </Dialog>
  )
}

export default RobotVersionInfo
