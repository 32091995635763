import React, { useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import styles from './AccountList.module.scss'
import { cn } from '@src/utils/cn'
import { SubscriptionB } from '@src/logic/contexts/Subscriptions/SubscriptionsBackend.type'
import { useDataProvider } from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { formatDate } from '@src/utils/dateFormatter'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { SubscriptionF } from '@src/logic/contexts/Subscriptions/SubscriptionsFrontend.type'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'

interface SubscriptionInfoProps {
  open: boolean
  onClose: () => void
  account: AccountF | null
}

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  open,
  onClose,
  account,
}) => {
   
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionF[] | null>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (open) {
      setIsDataLoading(true)

      dataProvider
        .getList('Subscription', {
          pagination: { page: 0, perPage: 100 }, // Fixed 'perPage' typo
          sort: { field: 'id', order: 'ASC' },
          filter: { account_id: account?.id },
        })
        .then((response) => {
          const dataSubset = response.data.map((subscription: SubscriptionB) => ({
            id: subscription.id,
            customerId: subscription.customer_id,
            accountId: subscription.account_id,
            startDate: formatDate(subscription.start_date),
            endDate: formatDate(subscription.end_date),
            subscriptionTypId: subscription.subscription_type_id,
            accountName: subscription.account_name,
            status: subscription.status,
            paymentMethod: subscription.payment_method,
          }))

          setSubscriptionData(dataSubset)
        })
        .catch(() => setSubscriptionData(null))
        .finally(() => setIsDataLoading(false))
    }
  }, [open, account, dataProvider])

  return (
    <>
      

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='md'
        fullWidth
      >
        <div className={styles.dialogHeader}>
          <h3>Subscriptions of {account?.accountName}</h3>
        </div>

        <div className={styles.dialogContent}>
          <div className={cn(styles.headerRow, styles.headeSubscription)}>
            <strong>Account Name</strong>
            <strong>Type</strong>
            <strong>Start Date</strong>
            <strong>End Date</strong>
            <strong>Payment Method</strong>
            <strong>Status</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(subscriptionData) ? (
            <div className={sharedStyles.notFound}>
              No subscription matches your current search.
            </div>
          ) : (
            subscriptionData?.map((subscription, idx) => (
              <div className={cn(styles.infoRow, styles.infoSubscription)} key={idx}>
                <span>{subscription.accountName}</span>
                <span>{subscription.subscriptionTypId}</span>
                <span>{formatDate(subscription.startDate)}</span>
                <span>{formatDate(subscription.endDate)}</span>
                <span>
                  {subscription.paymentMethod.charAt(0).toUpperCase() +
                    subscription.paymentMethod.slice(1)}
                </span>
                <span>
                  {subscription.status.charAt(0).toUpperCase() +
                    subscription.status.slice(1)}
                </span>
              </div>
            ))
          )}
        </div>

        <div className={styles.dialogActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={onClose}
          >
            <span>Close</span>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default SubscriptionInfo
