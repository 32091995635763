import React, { useEffect, useState } from 'react'
import { Dialog, Tooltip } from '@mui/material'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import styles from '../AccountList.module.scss'
import { cn } from '@src/utils/cn'
import { LearnerB } from '@src/logic/contexts/Learners/LearnersBackend.type'
import { useDataProvider, useNotify } from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { LearnerF } from '@src/logic/contexts/Learners/LearnersFrontend.type'
import { mapStateToReadableStatus } from '@src/utils/mapStateToReadableState'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { formatDate } from '@src/utils/dateFormatter'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import LearnerSkillMap from './LearnerSkillMap'
import { rawLearnerToLearnerFullName } from '@src/logic/contexts/Learners/LearnerShowContext'

interface LearnerInfoProps {
  open: boolean
  onClose: () => void
  account: { id: string; accountName: string, accountCountry: string } | null
}

const LearnerInfo: React.FC<LearnerInfoProps> = ({ open, onClose, account }) => {
  const [learnerData, setLearnerData] = useState<LearnerF[] | null>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const [skillMapModalOpen, setSkillMapModalOpen] = useState(false)
  const [selectedLearner, setSelectedLearner] = useState(null)

  const notify = useNotify()

  const dataProvider = useDataProvider()

  useEffect(() => {
    if (open) {
      setIsDataLoading(true)

      dataProvider
        .update('Report', {
          id: account?.id,
          data: {
            filter: {},
            type: 'accounts_learners_list',
          },
          previousData: null,
        })
        .then((response) => {
          const reportData = response.data?.report ?? []

          const parsedLearnerData = reportData.map((learner: LearnerB) => ({
            id: learner.id,
            firstName: learner.first_name,
            lastName: learner.last_name,
            idCode: learner.id_code,
            fileCode: learner.file_code,
            grade: learner.grade,
            nickName: learner.nick_name,
            gender: learner.gender,
            state: learner.state,
            birthday: learner.birthday,
            fullName: rawLearnerToLearnerFullName(learner)
          }))
          setLearnerData(parsedLearnerData)
        })
        .catch(() => setLearnerData(null))
        .finally(() => setIsDataLoading(false))
    }
  }, [open, account, dataProvider])

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify('Copied ID clipboard - ' + text)
      })
      .catch((err) => {
        notify('Failed to Copy ID clipboard - ' + err)
      })
  }

  const openSkillMapModal = (learner) => {
    setSelectedLearner(learner)
    setSkillMapModalOpen(true)
  }

  const closeSkillMapModal = () => {
    setSkillMapModalOpen(false)
    setSelectedLearner(null)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth>
        <div className={styles.dialogHeader}>
          <h3>
            Learners of {account?.accountName} {learnerData?.length && ` (${learnerData?.length})`}
          </h3>
        </div>

        <div className={styles.dialogContent}>
          <div className={cn(styles.headerRow, styles.headeLearner)}>
            <strong>First Name</strong>
            <strong>Last Name</strong>
            <strong>ID Code</strong>
            <strong>File Code</strong>
            <strong>Grade</strong>
            <strong>Nick Name</strong>
            <strong>Gender</strong>
            <strong>Birthday</strong>
            <strong>State</strong>
            <strong>Skill Map</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(learnerData) ? (
            <div className={sharedStyles.notFound}>No learners assigned to the given account.</div>
          ) : (
            learnerData?.map((learner, idx) => (
              <div className={cn(styles.infoRow, styles.infoLearner)} key={idx}>
                <div className={cn(styles.cursorPointer)}>
                  <Tooltip
                    title={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', maxWidth: '300px' }}>
                        <span style={{ wordWrap: 'break-word', flex: 1 }}>{learner.id}</span>
                        <ContentCopyIcon
                          onClick={() => handleCopy(learner.id)}
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            color: '#4a90e2',
                          }}
                        />
                      </div>
                    }
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    {learner.firstName}
                  </Tooltip>
                </div>

                <span className={styles.wrapText}>{learner.lastName}</span>
                <span className={styles.wrapText}>{learner.idCode}</span>
                <span>{learner.fileCode}</span>
                <span>{learner.grade}</span>
                <span>{learner.nickName}</span>
                <span>{learner.gender}</span>
                <span>{formatDate(learner.birthday)}</span>
                <span>{mapStateToReadableStatus(learner.state)}</span>
                <span onClick={() => openSkillMapModal({id: learner.id, name: learner.fullName, accountName: account?.accountName , country: account?.accountCountry})} className={cn(styles.cursorPointer)}>
                  <AccountTreeIcon className={styles.iconAligned} />
                </span>
              </div>
            ))
          )}
        </div>

        <div className={styles.dialogActions}>
          <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onClose}>
            <span>Close</span>
          </div>
        </div>
      </Dialog>

      <LearnerSkillMap learner={selectedLearner} open={skillMapModalOpen} onClose={closeSkillMapModal} />
    </>
  )
}

export default LearnerInfo
