export type State = 'permanent_deleted' | 'deleted' | 'archived' | 'active' | 'on_hold' | 'excluded'

export const mapStateToReadableStatus = (status: State): string => {
  const statusMap: { [key in State]?: string } = {
    active: 'Active', 
    excluded: 'Excluded', 
    on_hold: 'On Hold', 
    archived: 'Archived',
    deleted: 'Deleted',
    permanent_deleted: 'Permanently Deleted'
  }
  return statusMap[status] || ''
}
