import { type ActivityStatusB, type GoalStatusB } from '@logic/contexts/Learners/LearnersBackend.type.ts'

export type TaskStatus = ActivityStatusB | GoalStatusB | 'on_hold' | 'not_introduced' | 'active' // ??

export const mapStatusToReadableStatus = (status: TaskStatus): string => {
  const statusMap: { [key in TaskStatus]?: string } = {
    active: 'Active', // ??
    not_introduced: 'Not Introduced',
    on_hold: 'On Hold',
    in_progress: 'In Progress',
    emerging: 'Emerging',
    generalized: 'Generalized',
    mastered: 'Mastered',
    achieved: 'Achieved',
    deleted: 'Deleted',
    permanent_deleted: 'Permanent Deleted'
  }
  return statusMap[status] || ''
}

