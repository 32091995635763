import { FC, useEffect } from 'react'
import styles from './LearnerAddNewTeamMember.module.scss'
import selectStyles from '@components/select/select.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelectInput } from '@components/select/selectInput.tsx'
import { ToggleButton } from '@components/toggleButton/toggleButton.tsx'
import { useForm, Controller } from 'react-hook-form'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { InfoOutlineIcon } from '@icons/utils'
import { LxIcon } from '@components/icon/Icon.tsx'
import { UserData } from '@src/pages/Learner/Show/TeamMembers/LearnerMembers.type.ts'
import { FilterObject } from '@logic/useFiltering.hook.ts'

import { useDataProvider, useNotify } from 'react-admin'
import usePrevious from '@src/hooks/usePrevious.hook.ts'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CAN_RUN_ALL_ACTIVITIES } from '@logic/contexts/AppStore/UserPermissions.constant.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

interface Props {
  isModalOpen: boolean
  onClose: () => void
  users: UserData
  learnerId: string
  superVisorId: string
  learnerFullName: string
  setRefreshKey: any
  assignedUserIds: any
}

const schema = yup
  .object()
  .shape({
    selectedMember: yup
      .object()
      .required('Selecting a team member is required.'),
    canRunActivities: yup.boolean(),
  })
  .required()

const getDefaultFormProps = (selectedMember: FilterObject<UserData> | null) => {
  return {
    canRunActivities: true,
    selectedMember: selectedMember,
    selectedSupervisor: null,
  }
}

export const AddNewLearnerAssistant: FC<Props> = ({
  onClose,
  users,
  learnerId,
  superVisorId,
  learnerFullName,
  setRefreshKey,
  assignedUserIds,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const {
    reset,
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultFormProps(null),
    resolver: yupResolver(schema),
  })

  const prevSelectedMemberId = usePrevious(watch('selectedMember')?.value.id)

  // Transform mockedUsers into an array of FilterObject, data structure used by LxSelectInput
  const allUsers: FilterObject<UserData>[] = users.map((user) => ({
    value: user,
    isEnabled: true,
    label: user.fullName,
    optionToRender: (
      <div className={selectStyles.selectInputOptionTwoColumn}>
        <span>{user.fullName}</span>
        <span> {user.userRole}</span>
      </div>
    ),
    id: user.id,
  }))

  const unAssignedUsers: FilterObject<UserData>[] = allUsers.filter(
    (user) => !assignedUserIds.has(user.id)
  )

  const selectedMember = watch('selectedMember')

  useEffect(() => {
    if (prevSelectedMemberId !== selectedMember?.value.id) {
      clearErrors('selectedMember')
      reset(getDefaultFormProps(selectedMember))
    }
  }, [reset, prevSelectedMemberId, selectedMember?.value.id])

  // canRunActivities - "AC-2310-UUL-004"
  // canViewAllGoals - "AC-2310-UL-002"
  // canEditDeleteAllGoals - "AC-2310-UL-003"

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      const { selectedMember, canRunActivities } = formData

      const accesses = []

      if (canRunActivities) {
        accesses.push(CAN_RUN_ALL_ACTIVITIES)
      }

      const payload = {
        assign_user: {
          user_id: selectedMember.id,
          supervisor_id: superVisorId,
          accesses: accesses,
          action: 'add_user',
        },
      }

      await dataProvider.update('Learner', {
        id: learnerId,
        data: payload,
        previousData: null,
      })
      notify('Assistant added successfully', { type: 'success' })
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setRefreshKey((prevKey) => prevKey + 1)
      onClose()
      setIsFormSubmitting(false)
    }
  }

  return (
    <div className={modalStyles.modalMd}>
      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <div className={modalStyles.dialogHeader}>
        <h3>Assign a new team member to {learnerFullName} </h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='selectedMember'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LxSelectInput
                className={styles.formField}
                value={value}
                onChange={onChange}
                options={unAssignedUsers}
                placeholder={'Select Team Member'}
              />
            )}
          />

          <>
            <div className={styles.optionalCheckboxRow}>
              <span>Can enroll in curriculums and run un-planned activities for {learnerFullName} </span>
              <LxTooltip
                tooltipText={
                  'Assistant can also run activities that are not pre-planned by the supervisor and the enroll the learner in new curriculums'
                }
              >
                <LxIcon icon={InfoOutlineIcon} />
              </LxTooltip>
              <Controller
                name='canRunActivities'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleButton value={value} onChange={onChange} />
                )}
              />
            </div>
          </>

          {errors.selectedMember && (
            <p className={styles.error}>{errors.selectedMember.message}</p>
          )}
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Cancel</span>
        </div>
        <div
          className={cn(
            'lxActionButton',
            'lxActionButtonFilled lxActionButtonDefaultSize'
          )}
          onClick={handleSubmit(onSubmit)}
        >
          <span>Assign</span>
        </div>
      </div>
    </div>
  )
}
