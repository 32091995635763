import React, { FC, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DataProvider, useNotify } from 'react-admin'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'
import { ActivitiesB, SkillAreaB, GoalB, UnitB } from '@logic/contexts/Activities/ActivitiesBackend.type.ts'
import {
  ActivitiesF, GoalF,
  SkillAreaF,
  UnitF
} from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { removeDuplicates } from '@src/utils/removeDuplicates.ts'
import { deepClone } from '@src/utils/deepClone.ts'
import {
  ActivityListFilterType,
  defaultActivityFilter,
  FilterObject,
  GOALS_FILTER_KEY,
  SKILL_AREAS_FILTER_KEY,
  useFiltering
} from '@logic/useFiltering.hook.ts'

export type LocalStorageUnit = {
  unitName: string,
  id: string,
  unitId: string,
  description: string
}

export type ActivitiesFilterOptions = {
  skillAreaFilters: FilterObject<string>[]
  goalFilters: FilterObject<string>[]
}

interface ActivitiesListType {
  displayData: ActivitiesF | null;
  allData: ActivitiesF | null;
  allUnits: LocalStorageUnit[];
  isLoading: boolean;
  filtersOptions: ActivitiesFilterOptions;
  filters: ActivityListFilterType;
  resetFilters: () => void;
}

const defaultActivitiesFilters: ActivitiesFilterOptions = {
  skillAreaFilters: [],
  goalFilters: [],
}

const ActivitiesListContextDefault = {
  displayData: null,
  allData: null,
  isLoading: true,
  allUnits: [],
  filtersOptions: defaultActivitiesFilters,
  filters: defaultActivityFilter,
  resetFilters: () => {}
};


const ActivitiesListContext = createContextFactory<ActivitiesListType>(ActivitiesListContextDefault)


const parseToFilterData = (data: ActivitiesF | null): ActivitiesFilterOptions => {
  let skillAreaFilters: FilterObject<string>[] = [];
  let goalFilters: FilterObject<string>[] = [];

  if (!isNilOrEmpty(data)) {
    
    
    data!.skillAreas.filter(skillArea => skillArea.type !== 'curriculum').forEach(skillArea => {
      skillAreaFilters.push({ value: skillArea.name, isEnabled: true, label: skillArea.name, type: skillArea.type });

      skillArea.goals.forEach(goal => {
        goalFilters.push({ value: goal.name, isEnabled: true, label: goal.name , type: goal.type });
      });
    });

    skillAreaFilters = removeDuplicates(skillAreaFilters, obj => obj.value);
    goalFilters = removeDuplicates(goalFilters, obj => obj.value);
  }

  return {
    skillAreaFilters,
    goalFilters,
  };
}


// Function to filter the sanitized data based on enabledCategories and enabledSubCategories
function filterActivitiesF(data: ActivitiesF, filters: ActivityListFilterType): ActivitiesF | null {
  if (isNilOrEmpty(data) || isNilOrEmpty(data.skillAreas)) {
    return null;
  }

  const filteredSkillAreas = data.skillAreas.filter(skillArea =>
    filters[SKILL_AREAS_FILTER_KEY].data.length === 0 ||
    filters[SKILL_AREAS_FILTER_KEY].data.some(enabledSkillArea => enabledSkillArea.value === skillArea.name && enabledSkillArea.isEnabled)
  );

  data.skillAreas = filteredSkillAreas.map(skillArea => {
    const goals = skillArea.goals.filter(goal =>
      filters[GOALS_FILTER_KEY].data.length === 0 ||
      filters[GOALS_FILTER_KEY].data.some(enabledGoal => enabledGoal.value === goal.name && enabledGoal.isEnabled)
    );

    skillArea.goals = goals;
    return skillArea;
  });

  return data
}

function mapActivitiesBToF(activitiesB: ActivitiesB | undefined): ActivitiesF | null {
  if (isNilOrEmpty(activitiesB)) {
    return null;
  }


  return {
    id: activitiesB!.id,
    name: activitiesB!.name,
    description: activitiesB!.description,
    type: activitiesB!.type,
    version: activitiesB!.version,
    skillAreas: activitiesB!.categories.map(category => mapSkillAreaBToF(category)),
    skillAreaIds: activitiesB!.categoriesIds
  };
}

function mapSkillAreaBToF(categoryB: SkillAreaB): SkillAreaF {
   return {
    id: categoryB.id,
    name: categoryB.name,
    description: categoryB.description,
    sortOrder: categoryB.sort_order,
    type: categoryB.type,
    goals: categoryB.sub_categories.map(mapGoalBToF),
    goalIds: categoryB.sub_categoriesIds,
    linkedCategoryName: categoryB.linked_category_name
  };
}

function mapGoalBToF(goalB: GoalB): GoalF {
  return {
    id: goalB.id,
    name: goalB.name,
    description: goalB.description,
    sortOrder: goalB.sort_order,
    type: goalB.type,
    units: goalB.units.map(mapUnitBToF),
    unitIds: goalB.unitsIds,
  };
}

function mapUnitBToF(unitB: UnitB): UnitF {
  return {
    id: unitB.id,
    title: unitB.title,
    description: unitB.description,
    sortOrder: unitB.sort_order,
    unitId: unitB.unit_id,
    reference: unitB.reference
  };
}

type Props = {
  dataProvider?: DataProvider
  children: ReactNode
}


export const ActivitiesListProvider: FC<Props> = ({ children, dataProvider }) => {
  const filteringConfig = [
    { key: SKILL_AREAS_FILTER_KEY },
    { key: GOALS_FILTER_KEY }
  ];

  const { filters, resetFilters } = useFiltering(filteringConfig, defaultActivityFilter);

  const [allData, setAllData] = useState<ActivitiesF>(null)
  const isFetching = useRef(false);
  const notify = useNotify()


  useEffect(() => {
    const fetchData = async () => {
      if (isFetching.current) return;  
      try {
        isFetching.current = true;
        if (dataProvider) {
          const response = await dataProvider.getOne<ActivitiesB>('CurriculumView', {
            id: ''
          })

          
          if (response.data) {
            setAllData(mapActivitiesBToF(response.data))
          } else {
             console.log('No activities found')
          }
        }
      } catch (error: any) {
        const errorMessage = error.message || 'Something went wrong'
        notify(errorMessage, { type: 'warning' })
      } finally {
        isFetching.current = false;
      }
    }
    fetchData()
  }, [])


  const filteredOutAllData = useMemo(() => filterActivitiesF(deepClone(allData), filters),
    [allData, filters[SKILL_AREAS_FILTER_KEY].data, filters[GOALS_FILTER_KEY].data]);


  const allUnits = useMemo(() => {
    if(isNilOrEmpty(allData)) {
      return []
    }

    const unitsTable: LocalStorageUnit[] = [];

    allData.skillAreas.forEach(skillArea =>
      skillArea.goals.forEach(
        goal =>
          goal.units.forEach(
            unit => unitsTable.push({unitName: unit.title, id: unit.id, unitId: unit.unitId, description: unit.description})
          )
      )
    )
    return unitsTable;
  }, [allData]);

  const filtersOptions = useMemo(() => parseToFilterData(allData), [allData])


  return (
    <ActivitiesListContext.Provider value={{
      displayData: filteredOutAllData,
      allData,
      allUnits,
      isLoading: isFetching.current,
      filtersOptions,
      filters,
      resetFilters
    }}>
      {children}
    </ActivitiesListContext.Provider>
  )
}

export const useActivitiesList = (): ActivitiesListType => {
  return useContext(ActivitiesListContext)
}
