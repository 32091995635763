import { CircularProgress } from '@mui/material'
import styles from './Dashboard.module.scss'
import { getDashboardSubmenu, type ResourceType } from '@src/pages/layout/Menu.tsx'
import { Link } from 'react-router-dom'
import { LxIcon } from '@components/icon/Icon.tsx'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { Suspense } from 'react'

// init
const  Dashboard = () => {
  const { currentUser, isUserLoading } = useUserContext() as any
  const DashboardSubmenu = getDashboardSubmenu(currentUser);

  return (
    <div className={styles.dashboardContainer}>
      <h2>Welcome {isUserLoading ? <CircularProgress/> : currentUser?.name}</h2>
      <Suspense fallback={<CircularProgress/>}>
        {
          DashboardSubmenu.map((subItem: ResourceType) => (
            <div key={`Navigation-row-${subItem.name}`} className={styles.navigationRowContainer}> {
              <Link key={subItem.type} to={subItem.type}>
                <div className={styles.navigationRow}>
                  {subItem?.icon ? <LxIcon sxStyles={{ height: '48px', width: '48px' }} icon={subItem.icon}></LxIcon> : null }
                  <span> {subItem.name} </span>
                </div>
              </Link>
            }</div>
          ))
        }
      </Suspense>
    </div>
  )
}

export default Dashboard
