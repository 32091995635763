import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import styles from './GroupCurriculum.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { LxIcon } from '@src/components/icon/Icon'
import { cn } from '@src/utils/cn'
import { PlusOutlineIcon } from '@icons/utils'
import CurriculumEnrollModal from './GroupCurriculumEnroll'
import { useModal } from '@src/logic/contexts/Modal/ModalContext'
import { generateSimpleOptions } from '@src/logic/useFiltering.hook'
import {  CurriculumIndIcon } from '@src/assets/icons'
import { getSvgComponent } from '@src/assets/icons/skillAreas/svgMapper.util'

export const GroupCurriculum = () => {
  const { displayData, isLoading } = useActivitiesList()
  const { learnerList, isLoading: isLearnerListLoading } = useLearnersList()
  const { showModal } = useModal()

  // Group curriculums by linkedCategoryName
  const groupedCurriculums = !isNilOrEmpty(displayData)
    ? displayData?.skillAreas
        .filter((skillArea) => skillArea.type === 'curriculum')
        .reduce((acc, curriculum) => {
          const category = curriculum.linkedCategoryName || 'Uncategorized'
          if (!acc[category]) acc[category] = []
          acc[category].push(curriculum)
          return acc
        }, {})
    : {}

  const learnerOptions = generateSimpleOptions(learnerList, 'fullName')

  return (
    <div className={styles.activitiesContainer}>
      {isLoading || isLearnerListLoading ? (
        <div className={styles.placeholderContainer}>
          <LxLoadingSpinner />
        </div>
      ) : (
        <>
          {Object.entries(groupedCurriculums).map(([category, curriculums]) => (
            <div key={category} className={styles.categoryContainer}>
              <div className={cn(styles.skillAreaRow)}>
                <LxIcon
                  sxStyles={{ height: '32px', width: '32px' }}
                  customViewBox={'0 0 1320 1320'}
                  icon={getSvgComponent(category.replace('Curriculums - ', ''))}
                />

                <h4>{category} Curriculums</h4>
              </div>
              {curriculums.map((curriculum) => (
                <div
                  key={curriculum.id}
                  className={styles.curriculumContainer}
                  onClick={() => showModal(<CurriculumEnrollModal curriculum={curriculum} allLearners={learnerOptions} />)}
                >
                  <div className={styles.curriculumHeader}>
                    <div className={styles.curriculumContent}>
                      <LxIcon sxStyles={{ height: '24px', width: '24px', marginRight: '8px' }} icon={CurriculumIndIcon} />
                      {curriculum.name.replace('Curriculum - ', '')}
                    </div>
                    <LxIcon sxStyles={{ height: '40px', width: '40px', cursor: 'pointer' }} icon={PlusOutlineIcon} />
                  </div>
                </div>
              ))}
            </div>
          ))}
          {isNilOrEmpty(groupedCurriculums) && (
            <div className={styles.placeholderContainer}>
              <h5>No data found, please try changing filters</h5>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default GroupCurriculum
