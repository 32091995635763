import React, { useContext, useMemo} from 'react';
import { useGetList } from 'react-admin';
import { AccounAddressB, AccountB, AccountLinkedAccountB } from '@src/logic/contexts/Accounts/AccountsBackend.type'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'

interface AccountContextType {
  accountList: AccountF[] | null;
  isLoading: boolean;
  filterValues: FilterValues;
  dispatch: DispatchType;
}

const AccountListContextDefault = {
  accountList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    state: 'active'
  },
  dispatch: () => {},
};

const AccountListContext = createContextFactory<AccountContextType>(AccountListContextDefault);

const mapServerAccountListToClient = (data: AccountB[] | undefined): AccountF[] | null => {
  if (isNilOrEmpty(data)) {
    return null;
  }
  

  return data!.map((account: AccountB) => ({
    id: account.id,
    accountName: account.account_name,
    accountType: account.account_type,
    addresses: account.address.map((address: AccounAddressB) => ({
      addressTypes: address.address_types,
      addressLine1: address.address_line_1,
      addressLine2: address.address_line_2,
      addressLine3: address.address_line_3,
      city: address.city,
      state: address.state,
      country: address.country,
      phone: address.phone,
      postcode: address.postcode
    })),
    contacts: account.contacts, 
    customerId: account.customer_id, 
    accountCountry: account.account_country, 
    primaryEmail: account.primary_account_email ?? "",
    robots: account.robots, 
    subscriptions: account.subscriptions , 
    linkedAccounts: account.linked_accounts?.map((linkedAccount: AccountLinkedAccountB) => ({
      accountName: linkedAccount.account_name,
      linkedType: linkedAccount.relation
    }))
  }));
};

export const AccountListProvider: React.FC = ({ children, filter }) => {
  const initialFilterValues = { Q: '' };
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues);

  const { data, isLoading } = useGetList<AccountB>(
    'Account',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'last_update', order: 'DESC' },
      filter: filter ? filter : filterValues
    }
  );
  

  const sanitizedAccountList = useMemo(() => mapServerAccountListToClient(data), [data]);

  return (
    <AccountListContext.Provider value={{ accountList: sanitizedAccountList, filterValues, isLoading, dispatch }}>
      {children}
    </AccountListContext.Provider>
  );
};

export const useAccountsList = (): AccountContextType => {
  return useContext(AccountListContext);
};
