import React, { useEffect, useState } from "react";
import {
  GoalF,
} from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import styles from "./common.module.scss";
import { isNilOrEmpty } from "@src/utils/isNilOrEmpty.ts";
import { ActivitiesUnit } from "@src/pages/Activities/components/ActivitiesUnit.tsx";
import { LxClickAbleIcon } from "@components/icon/clickAbleIcon.tsx";
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'
import { cn } from "@src/utils/cn.ts";
import { useUserContext } from "@logic/contexts/AppStore/UserContext.tsx";
import { usePolicyContext } from "@logic/contexts/AppStore/AbacContext.tsx";
import { ActivitiesGoalCreate } from '@src/pages/Activities/components/ActivitiesGoalCreate.tsx'

interface Props {
  goal: GoalF;
  expandAll: boolean;
  goalNumber: number;
}

export const ActivitiesGoal: React.FC<Props> = ({
  categoryName,
  categoryId,
  goal,
  expandAll,
  goalNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { currentUser } = useUserContext() as any;
  const { canPerformAction } = usePolicyContext();

  const canAddGoal = canPerformAction(
    currentUser,
    { type: "Learner" },
    "add_goal"
  );

  useEffect(() => {
    setIsOpen(expandAll);
  }, [expandAll]);

  return (
    <div className={styles.goalContainer}>
      <div className={styles.goalHeader}>
        <div>
          <h5 onClick={() => setIsOpen(!isOpen)}>
            Goal number {goalNumber}: {goal.name}
          </h5>
          <LxClickAbleIcon
            className={cn(styles.activityAction, styles.arrowDownUp)}
            icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
            customViewBox={"0 0 960 560"}
            onClick={() => setIsOpen((prev) => !prev)}
          ></LxClickAbleIcon>
        </div>
        {canAddGoal ? (
            <ActivitiesGoalCreate skillAreaName={categoryName} skillAreaId={categoryId} goal={goal}/>
        ) : null }
      </div>

      {isOpen &&
        !isNilOrEmpty(goal.units) &&
        goal.units.map((unit, index) => (
          <React.Fragment key={`${unit.title}-${index}`}>
            <ActivitiesUnit unit={unit} />
          </React.Fragment>
        ))}
    </div>
  );
};
